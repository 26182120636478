export const Icon = {
  ActivityCalendar: dynamic(() => import('./ActivityCalendar').then((mod) => mod.ActivityCalendar)),
  AddFriend: dynamic(() => import('./AddFriend').then((mod) => mod.AddFriend)),
  AlternateCheckmark: dynamic(() =>
    import('./AlternateCheckmark').then((mod) => mod.AlternateCheckmark),
  ),
  ApplePay: dynamic(() => import('./ApplePay').then((mod) => mod.ApplePay)),
  ArrowCircle: dynamic(() => import('./ArrowCircle').then((mod) => mod.ArrowCircle)),
  AudioFilterButton: dynamic(() =>
    import('./AudioFilterButton').then((mod) => mod.AudioFilterButton),
  ),
  LevelUpFlag: dynamic(() => import('./LevelUpFlag').then((mod) => mod.LevelUpFlag)),
  Beta: dynamic(() => import('./Beta').then((mod) => mod.Beta)),
  BoltGradient: dynamic(() => import('./BoltGradient').then((mod) => mod.BoltGradient)),
  Bookmark: dynamic(() => import('./Bookmark').then((mod) => mod.Bookmark)),
  Broadcast: dynamic(() => import('./Broadcast').then((mod) => mod.Broadcast)),
  Cake: dynamic(() => import('./Cake').then((mod) => mod.Cake)),
  Calendar: dynamic(() => import('./Calendar').then((mod) => mod.Calendar)),
  CalendarGradient: dynamic(() => import('./CalendarGradient').then((mod) => mod.CalendarGradient)),
  Camera: dynamic(() => import('./Camera').then((mod) => mod.Camera)),
  CameraInactive: dynamic(() => import('./CameraInactive').then((mod) => mod.CameraInactive)),
  ChatButton: dynamic(() => import('./ChatButton').then((mod) => mod.ChatButton)),
  Checkmark: dynamic(() => import('./Checkmark').then((mod) => mod.Checkmark)),
  ClassCheckmark: dynamic(() => import('./ClassCheckmark').then((mod) => mod.ClassCheckmark)),
  CheckmarkCircle: dynamic(() => import('./CheckmarkCircle').then((mod) => mod.CheckmarkCircle)),
  TiltedCheckmark: dynamic(() => import('./TiltedCheckmark').then((mod) => mod.TiltedCheckmark)),
  Chevron: dynamic(() => import('./Chevron').then((mod) => mod.Chevron)),
  ChevronWide: dynamic(() => import('./ChevronWide').then((mod) => mod.ChevronWide)),
  Clock: dynamic(() => import('./Clock').then((mod) => mod.Clock)),
  CloseX: dynamic(() => import('./CloseX').then((mod) => mod.CloseX)),
  Desktop: dynamic(() => import('./Desktop').then((mod) => mod.Desktop)),
  Document: dynamic(() => import('./Document').then((mod) => mod.Document)),
  Download: dynamic(() => import('./Download').then((mod) => mod.Download)),
  Dumbell: dynamic(() => import('./Dumbell').then((mod) => mod.Dumbell)),
  Ellipsis: dynamic(() => import('./Ellipsis').then((mod) => mod.Ellipsis)),
  EmptyStats: dynamic(() => import('./EmptyStats').then((mod) => mod.EmptyStats)),
  Emoticon: dynamic(() => import('./Emoticon').then((mod) => mod.Emoticon)),
  ErrorSquare: dynamic(() => import('./ErrorSquare').then((mod) => mod.ErrorSquare)),
  ExitClassButton: dynamic(() => import('./ExitClassButton').then((mod) => mod.ExitClassButton)),
  Eye: dynamic(() => import('./Eye').then((mod) => mod.Eye)),
  EyeClosed: dynamic(() => import('./EyeClosed').then((mod) => mod.EyeClosed)),
  Facebook: dynamic(() => import('./Facebook').then((mod) => mod.Facebook)),
  FilledBookmark: dynamic(() => import('./FilledBookmark').then((mod) => mod.FilledBookmark)),
  Fire: dynamic(() => import('./Fire').then((mod) => mod.Fire)),
  FullVolume: dynamic(() => import('./FullVolume').then((mod) => mod.FullVolume)),
  FreemiumClock: dynamic(() => import('./FreemiumClock').then((mod) => mod.FreemiumClock)),
  FreemiumLockIcon: dynamic(() => import('./FreemiumLockIcon').then((mod) => mod.FreemiumLockIcon)),
  FreemiumStateIcon: dynamic(() =>
    import('./FreemiumStateIcon').then((mod) => mod.FreemiumStateIcon),
  ),
  Friended: dynamic(() => import('./Friended').then((mod) => mod.Friended)),
  Friends: dynamic(() => import('./Friends').then((mod) => mod.Friends)),
  GiftNew: dynamic(() => import('./GiftNew').then((mod) => mod.GiftNew)),
  GreyXCircle: dynamic(() => import('./GreyXCircle').then((mod) => mod.GreyXCircle)),
  PartyFullScreenArrow: dynamic(() =>
    import('./PartyFullScreenArrow').then((mod) => mod.PartyFullScreenArrow),
  ),
  Heart: dynamic(() => import('./Heart').then((mod) => mod.Heart)),
  Headphones: dynamic(() => import('./Headphones').then((mod) => mod.Headphones)),
  HeavyCheckmark: dynamic(() => import('./HeavyCheckmark').then((mod) => mod.HeavyCheckmark)),
  History: dynamic(() => import('./History').then((mod) => mod.History)),
  HubChat: dynamic(() => import('./HubChat').then((mod) => mod.HubChat)),
  HubWeight: dynamic(() => import('./HubWeight').then((mod) => mod.HubWeight)),
  Info: dynamic(() => import('./Info').then((mod) => mod.Info)),
  Instagram: dynamic(() => import('./Instagram').then((mod) => mod.Instagram)),
  IPad: dynamic(() => import('./IPad').then((mod) => mod.IPad)),
  IPhone: dynamic(() => import('./IPhone').then((mod) => mod.IPhone)),
  Laptop: dynamic(() => import('./Laptop').then((mod) => mod.Laptop)),
  LeaveButton: dynamic(() => import('./LeaveButton').then((mod) => mod.LeaveButton)),
  LeftQuotation: dynamic(() => import('./LeftQuotation').then((mod) => mod.LeftQuotation)),
  LighteningHex: dynamic(() => import('./LighteningHex').then((mod) => mod.LighteningHex)),
  Lock: dynamic(() => import('./Lock').then((mod) => mod.Lock)),
  NavToggle: dynamic(() => import('./NavToggle').then((mod) => mod.NavToggle)),
  Hamburger: dynamic(() => import('./Hamburger').then((mod) => mod.Hamburger)),
  HamburgerClose: dynamic(() => import('./HamburgerClose').then((mod) => mod.HamburgerClose)),
  NewBadge: dynamic(() => import('./NewBadge').then((mod) => mod.NewBadge)),
  Note: dynamic(() => import('./Note').then((mod) => mod.Note)),
  NoVolume: dynamic(() => import('./NoVolume').then((mod) => mod.NoVolume)),
  Mail: dynamic(() => import('./Mail').then((mod) => mod.Mail)),
  MessageDelete: dynamic(() => import('./MessageDelete').then((mod) => mod.MessageDelete)),
  MessageEdit: dynamic(() => import('./MessageEdit').then((mod) => mod.MessageEdit)),
  Mic: dynamic(() => import('./Mic').then((mod) => mod.Mic)),
  MicAndCam: dynamic(() => import('./MicAndCam').then((mod) => mod.MicAndCam)),
  MicInactive: dynamic(() => import('./MicInactive').then((mod) => mod.MicInactive)),
  Music: dynamic(() => import('./Music').then((mod) => mod.Music)),
  MuteVolume: dynamic(() => import('./MuteVolume').then((mod) => mod.MuteVolume)),
  ObeSmiley: dynamic(() => import('./ObeSmiley').then((mod) => mod.ObeSmiley)),
  OpenEnvelope: dynamic(() => import('./OpenEnvelope').then((mod) => mod.OpenEnvelope)),
  PaperClip: dynamic(() => import('./PaperClip').then((mod) => mod.PaperClip)),
  Party: dynamic(() => import('./Party').then((mod) => mod.Party)),
  PartyButton: dynamic(() => import('./PartyButton').then((mod) => mod.PartyButton)),
  PartyButtonBlack: dynamic(() => import('./PartyButtonBlack').then((mod) => mod.PartyButtonBlack)),
  PartyVideoBack15: dynamic(() => import('./PartyVideoBack15').then((mod) => mod.PartyVideoBack15)),
  PartyVideoFwd15: dynamic(() => import('./PartyVideoFwd15').then((mod) => mod.PartyVideoFwd15)),
  PartyVideoPause: dynamic(() => import('./PartyVideoPause').then((mod) => mod.PartyVideoPause)),
  PartyVideoPlay: dynamic(() => import('./PartyVideoPlay').then((mod) => mod.PartyVideoPlay)),
  PartyWithShadow: dynamic(() => import('./PartyWithShadow').then((mod) => mod.PartyWithShadow)),
  Pencil: dynamic(() => import('./Pencil').then((mod) => mod.Pencil)),
  Pin: dynamic(() => import('./Pin').then((mod) => mod.Pin)),
  PlayCards: dynamic(() => import('./PlayCards').then((mod) => mod.PlayCards)),
  Print: dynamic(() => import('./Print').then((mod) => mod.Print)),
  PurpleChevron: dynamic(() => import('./PurpleChevron').then((mod) => mod.PurpleChevron)),
  PTChatIcon: dynamic(() => import('./PTChatIcon').then((mod) => mod.PTChatIcon)),
  PurpleCircleCheck: dynamic(() =>
    import('./PurpleCircleCheck').then((mod) => mod.PurpleCircleCheck),
  ),
  RatingStar: dynamic(() => import('./RatingStar').then((mod) => mod.RatingStar)),
  Replay: dynamic(() => import('./Replay').then((mod) => mod.Replay)),
  RecommendationsBadge: dynamic(() =>
    import('./RecommendationsBadge').then((mod) => mod.RecommendationsBadge),
  ),
  RecommendingBadge: dynamic(() =>
    import('./RecommendingBadge').then((mod) => mod.RecommendingBadge),
  ),
  ReducedVolume: dynamic(() => import('./ReducedVolume').then((mod) => mod.ReducedVolume)),
  Search: dynamic(() => import('./Search').then((mod) => mod.Search)),
  SearchNew: dynamic(() => import('./SearchNew').then((mod) => mod.Search)),
  SecureLock: dynamic(() => import('./SecureLock').then((mod) => mod.SecureLock)),
  Send: dynamic(() => import('./Send').then((mod) => mod.Send)),
  ShareButton: dynamic(() => import('./ShareButton').then((mod) => mod.ShareButton)),
  Sparkle: dynamic(() => import('./Sparkle').then((mod) => mod.Sparkle)),
  Spinner: dynamic(() => import('./Spinner').then((mod) => mod.SpinnerIcon)),
  Star: dynamic(() => import('./Star').then((mod) => mod.Star)),
  ThumbsUp: dynamic(() => import('./ThumbsUp').then((mod) => mod.ThumbsUp)),
  ThreadReply: dynamic(() => import('./ThreadReply').then((mod) => mod.ThreadReply)),
  TooltipInfo: dynamic(() => import('./TooltipInfo').then((mod) => mod.TooltipInfo)),
  Upload: dynamic(() => import('./Upload').then((mod) => mod.Upload)),
  VolumeButton: dynamic(() => import('./VolumeButton').then((mod) => mod.VolumeButton)),
  X: dynamic(() => import('./X').then((mod) => mod.X)),
  WorkoutParty: dynamic(() => import('./WorkoutParty').then((mod) => mod.WorkoutParty)),
  YellowGift: dynamic(() => import('./YellowGift').then((mod) => mod.YellowGift)),
  WorkoutPartyIcon: dynamic(() => import('./WorkoutPartyIcon').then((mod) => mod.WorkoutPartyIcon)),
  NoteEdit: dynamic(() => import('./NoteEdit').then((mod) => mod.NoteEdit)),
  Dot: dynamic(() => import('./DotIcon').then((mod) => mod.DotIcon)),
  ClassStackIcon: dynamic(() => import('./ClassStack').then((mod) => mod.ClassStackIcon)),
  ClassStackCheckedIcon: dynamic(() =>
    import('./ClassStackChecked').then((mod) => mod.ClassStackCheckedIcon),
  ),
  NewCLass: dynamic(() => import('./NewClass').then((mod) => mod.NewClass)),
  Gym: dynamic(() => import('./Gym').then((mod) => mod.Gym)),
}
